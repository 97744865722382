<template>
  <div class="exportersMobile">
    <template v-if="tableData.length">
      <div class="exportersMobile__table">
        <div class="tableItem exportersMobile__table-choiceAll">
          <el-checkbox
            v-model="checkAll"
            class="tableItem__checkBox"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            <span class="tableItem__content-title data__title">
              Выбрать все
            </span>
          </el-checkbox>

          <div
            v-if="checkedExporters.length > 1"
            class="tableItem__removeItems"
            @click="handleTableMenu('controlTableRemoveSelectedItems')"
          >
            Удалить выбранные
          </div>
        </div>

        <el-checkbox-group
          v-model="checkedExporters"
          @change="handleCheckedChange"
        >
          <el-checkbox
            v-for="(item, index) in tableData"
            :key="index"
            :label="item"
            class="exportersMobile__table-list tableItem"
          >
            <div class="tableItem__content">
              <div class="tableItem__content-data">
                <div>
                  <div class="data__title">{{ item.name }}</div>
                  <div class="data__inn">ИНН {{ item.inn }}</div>
                </div>

                <div v-if="!isViewer" class="data-buttons">
                  <IconPenMobile @onClick="handleEdit(item)" />
                  <IconTrashMobile @onClick="handleDelete(item)" />
                </div>
              </div>

              <el-link
                v-for="(user, index2) in item.users"
                :key="index2"
                :href="'tel:' + user.phone"
                :underline="false"
              >
                <div class="tableItem__content-users">
                  <div class="user__phone">{{ user.phone }}</div>
                  <div class="user__name">{{ user.name }}</div>
                </div>

                <PhoneIcon />
              </el-link>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <div class="containerMobile">
        <BottomToolbarButton
          title="Добавить экспортера"
          @button-clicked="handleTableMenu('controlTableAddItem')"
        />
      </div>
    </template>
    <TableEmpty
      v-else
      warning="У вас не создано ни одного экспортера"
      proposition="Добавить экспортера"
      @add="handleTableMenu('controlTableAddItem')"
    />
  </div>
</template>

<script>
import BottomToolbarButton from '@/UI/form/buttons/BottomToolbarButton'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import PhoneIcon from '@/assets/icons/PhoneIcon'
import TableEmpty from '@/UI/table/table-empty/TableEmpty.vue'

export default {
  name: 'ControlTableExportersMobile',
  components: {
    IconTrashMobile,
    IconPenMobile,
    PhoneIcon,
    BottomToolbarButton,
    TableEmpty,
  },
  props: {
    tableData: { type: Array, required: true },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedExporters: [],
      exporters: this.tableData,
    }
  },
  methods: {
    emitExporters(val) {
      this.$emit('selectionChange', {
        ids: val.map(item => item.id),
        names: val.map(item => item.name),
        local_names:
          val.map(item => (item.local_name ? item.local_name : item.name)) ||
          [],
      })
    },
    handleCheckAllChange(val) {
      this.checkedExporters = val ? this.tableData : []
      this.isIndeterminate = false

      this.emitExporters(this.checkedExporters)
    },
    handleCheckedChange(val) {
      let checkedCount = val.length

      this.checkAll = checkedCount === this.exporters.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.exporters.length

      this.emitExporters(val)
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
    handleEdit(row) {
      this.$emit('editRow', row)
    },
    handleDelete(row) {
      this.$emit('deleteRow', row)
    },
  },
}
</script>

<style lang="sass" scoped>
.data__title
  font-weight: 400
  font-size: 14px
  white-space: break-spaces

.data__inn
  margin-top: 8px
  font-size: 12px
  color: $grey-font

.exportersMobile
  &__table
    margin-bottom: 60px
    &-choiceAll
      display: flex
      align-items: center
      justify-content: space-between
      column-gap: 16px

.tableItem
  display: flex
  column-gap: 4px
  background: #FFFFFF
  padding: 12px 16px
  margin-bottom: 8px
  margin-right: 0
  border: 1px solid $greyBorder
  &__removeItems
    font-weight: 500
    font-size: 14px
    color: $color-red
  &__content
    display: flex
    flex-direction: column
    width: 100%
    &-data
      display: flex
      align-items: center
      justify-content: space-between
      border-bottom: 1px solid #EBEEF5
      padding-bottom: 14px
      margin-bottom: 6px
      .data-buttons
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 12px
    &-users
      font-weight: 400
      margin-top: 8px
      .user__phone
        font-size: 14px
        color: $color-blue-primary
      .user__name
        font-size: 12px
        color: $grey-font
</style>
